<template>
  <div>
    <b-card>
      <b-row>
        <!-- Organization Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- Organization Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="organizationData.avatar"
              :text="avatarText(organizationData.name)"
              :variant="`light-${resolveOrganizationRoleVariant(
                organizationData.role
              )}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ organizationData.name }}
                </h4>
                <span class="card-text">{{ organizationData.email }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  :to="{
                    name: 'apps-organizations-edit',
                    params: { id: organizationData.id },
                  }"
                  variant="primary"
                >
                  Edit
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-form class="mt-1">
        <b-row>
          <b-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-3 mb-1">
            <h4>Organization Details</h4>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="organizationData.name"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
            v-if="organizationData.gcp_billing_account_id"
          >
            <b-form-group
              label="Gcp Billing Account Id"
              label-for="gcp_billing_account_id"
            >
              <b-form-input
                id="gcp_billing_account_id"
                v-model="organizationData.gcp_billing_account_id"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="organizationData.cloud_customer_id">
            <b-form-group
              label="Cloud Customer Id"
              label-for="cloud_customer_id"
            >
              <b-form-input
                id="cloud_customer_id"
                v-model="organizationData.cloud_customer_id"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="organizationData.cloud_identity_id">
            <b-form-group
              label="Cloud Identity Id"
              label-for="cloud_identity_id"
            >
              <b-form-input
                id="domacloud_identity_idin"
                v-model="organizationData.cloud_identity_id"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Domain" label-for="domain">
              <b-form-input
                id="domain"
                v-model="organizationData.domain"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Region" label-for="region-type">
              <b-form-input v-model="regionTypeName" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Currency" label-for="currency-type">
              <b-form-input v-model="currencyTypeName" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Account Manager" label-for="account_manager">
              <b-form-input v-model="accountManagerTypeName" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Support Tiers" label-for="support_tiers">
              <b-form-input v-model="supportTiersName" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Country" label-for="Country">
              <b-form-input v-model="countryTypeName" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="stateTypeName">
            <b-form-group label="State" label-for="State">
              <b-form-input v-model="stateTypeName" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="!stateTypeName">
            <b-form-group label="State" label-for="State">
              <b-form-input v-model="organizationData.state_code" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="City" label-for="City">
              <b-form-input v-model="organizationData.city" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Postal code" label-for="postal_code">
              <b-form-input v-model="organizationData.postal_code" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Language Code" label-for="language_code">
              <b-form-input v-model="languageCodeTypeName" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              label="Company Address"
              label-for="company_address_lines1"
            >
              <b-form-input
                id="company_address_lines1"
                v-model="organizationData.address_lines1"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="organizationData.address_lines2">
            <b-form-group
              label="Company Address1"
              label-for="company_address_lines2"
            >
              <b-form-input
                id="company_address_lines2"
                v-model="organizationData.address_lines2"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="organizationData.address_lines3">
            <b-form-group
              label="Company Address2"
              label-for="company_address_lines3"
            >
              <b-form-input
                id="company_address_lines3"
                v-model="organizationData.address_lines3"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="userData.role_name == 'GLOBAL_ADMIN'">
          <b-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-3 mb-1"
            ><h4>Discount Details</h4></b-col
          >

          <b-col cols="12" md="4">
            <b-form-group
              label="Google Cloud Discount"
              label-for="google_cloud_discount"
            >
              <b-input-group>
                <b-form-input
                  v-model="organizationData.google_cloud_discount"
                  readonly
                />
                <b-input-group-append is-text>
                  <feather-icon icon="PercentIcon" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              label="Google Workspace Discount"
              label-for="google_workspace_discount"
            >
              <b-input-group>
                <b-form-input
                  v-model="organizationData.google_workspace_discount"
                  readonly
                />
                <b-input-group-append is-text>
                  <feather-icon icon="PercentIcon" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-3 mb-1"
            ><h4>Contact Details</h4></b-col
          >
          <b-col cols="12" md="4">
            <b-form-group label="First Name" label-for="first_name">
              <b-form-input v-model="organizationData.first_name" readonly />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Last Name" label-for="last_name">
              <b-form-input v-model="organizationData.last_name" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="organizationData.email"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Alternate Email" label-for="alternate_email">
              <b-form-input
                v-model="organizationData.alternate_email"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Phone" label-for="Phone">
              <b-form-input v-model="organizationData.phone_number" readonly />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Role" label-for="Role">
              <b-form-input v-model="role" readonly />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <subscription
      v-if="organizationData"
      :organization-data="organizationData"
    ></subscription>
  </div>
</template>
<script>
import {
  BCard,
  BImg,
  BCardText,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  VBTooltip,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useOrganizationsList from "../organizations-list/useOrganizationsList";
import store from "@/store";
import { ref, emit } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import axios from "@axios";
import vSelect from "vue-select";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Subscription from "@core/components/subscription/Subscription.vue";

export default {
  components: {
    Subscription,
    Loading,
    BCard,
    BImg,
    BCardText,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BAvatar,
    vSelect,
  },
  props: {
    organizationData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup(props, { emit }) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const toast = useToast();
    let currencyTypes = ref([]);
    let currencyTypeName = ref("");
    let regionTypes = ref([]);
    let regionTypeName = ref("");
    let countryTypes = ref([]);
    let countryTypeName = ref("");
    let stateTypes = ref([]);
    let stateTypeName = ref("");
    let languageCodeTypes = ref([]);
    let languageCodeTypeName = ref("");
    let accountManagerTypes = ref([]);
    let accountManagerTypeName = ref("");
    let supportTiers = ref([]);
    let supportTiersName = ref("");
    let role = ref("ORG_ADMIN");

    store
      .dispatch("app-organization/fetchCurrencyTypes")
      .then((response) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        currencyTypes.value = response.data;
        currencyTypes.value.filter(function (value) {
          if (value.id == props.organizationData.currency_id) {
            currencyTypeName.value = value.name;
          }
        });
      })
      .catch(() => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching currency types",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchRegionTypes")
      .then((response) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        regionTypes.value = response.data;
        regionTypes.value.filter(function (value) {
          if (value.id == props.organizationData.region_id) {
            regionTypeName.value = value.name;
          }
        });
      })
      .catch(() => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching region types",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchCountries")
      .then((response) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        countryTypes.value = response.data.countries;
        countryTypes.value.filter(function (value) {
          if (value.country_code == props.organizationData.country_code) {
            countryTypeName.value = value.name;
            axios
              .get(
                `/region/countries/${props.organizationData.country_code}/states`
              )
              .then((response) => {
                stateTypes.value = response.data.states;
                stateTypes.value.filter(function (value) {
                  if (value.state_code == props.organizationData.state_code) {
                    stateTypeName.value = value.name;
                  }
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      })
      .catch(() => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching countries",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchLanguageCodes")
      .then((response) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        languageCodeTypes.value = response.data.language_codes;
        languageCodeTypes.value.filter(function (value) {
          if (value.code == props.organizationData.language_code) {
            languageCodeTypeName.value = value.name;
          }
        });
      })
      .catch(() => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching lanaguage codes",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchAccountManager")
      .then((response) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        accountManagerTypes.value = response.data;
        accountManagerTypes.value.filter(function (value) {
          if (value.uid == props.organizationData.account_manager) {
            accountManagerTypeName.value = value.display_name;
          }
        });
      })
      .catch(() => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching account manager",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    store
      .dispatch("app-organization/fetchSupportTiers")
      .then((response) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        supportTiers.value = response.data.support_tiers;
        supportTiers.value.filter(function (value) {
          if (value == props.organizationData.support_tier) {
            supportTiersName.value = value;
          }
        });
      })
      .catch(() => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching support tiers",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    const { resolveOrganizationRoleVariant } = useOrganizationsList();
    return {
      avatarText,
      resolveOrganizationRoleVariant,
      userData,
      currencyTypeName,
      regionTypeName,
      countryTypeName,
      stateTypeName,
      languageCodeTypeName,
      accountManagerTypeName,
      supportTiersName,
      role,
    };
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";
@import "../organizationStyle.scss";

.v-select {
  margin: 0px !important;
  width: 100%;
}
.feather {
  margin-top: 3px;
  margin-right: 6px;
}
.CheckCircleIcon {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 50px;
  margin-right: 50px;
}
</style>
