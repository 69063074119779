<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="organizationData === undefined">
      <h4 class="alert-heading">Error fetching organization data</h4>
      <div class="alert-body">
        No organization found with this organization id. Check
        <b-link class="alert-link" :to="{ name: 'apps-organizations-list' }">
          Organization List
        </b-link>
        for other organizations.
      </div>
    </b-alert>

    <template v-if="organizationData">
      <!-- First Row -->
      <b-breadcrumb class="pl-0 pb-1">
        <b-breadcrumb-item :to="{ name: 'apps-organizations-list' }">
          Organizations
        </b-breadcrumb-item>
        <b-breadcrumb-item active> Organization Details </b-breadcrumb-item>
      </b-breadcrumb>
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <organization-view-organization-info-card
            :organization-data="organizationData"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import organizationStoreModule from "../organizationStoreModule";
import OrganizationViewOrganizationInfoCard from "./OrganizationViewOrganizationInfoCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,

    // Local Components
    OrganizationViewOrganizationInfoCard,
  },
  setup() {
    const organizationData = ref(null);

    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";

    // Register module
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });
    store.commit("appConfig/SPINNER_CONFIG", true);

    store
      .dispatch("app-organization/fetchOrganization", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        organizationData.value = response.data;
        store.commit("appConfig/SPINNER_CONFIG", false);
      })
      .catch((error) => {
        store.commit("appConfig/SPINNER_CONFIG", false);

        if (error.response.status === 404) {
          organizationData.value = undefined;
        }
      });

    return {
      organizationData,
    };
  },
};
</script>
